<template>
    <div class="image-info">
        <div class="image-left">
            <div class="Dynamic">
                <div class="head">
                    <div class="user">
                        <img :src="data.user.headIco" class="userIco">
                        <div class="major">
                            <div class="userName">
                                <div>{{data.user.nickName}}</div>
                                <img src="../room/imgs/v1.png" style="width: 15px;height: 15px;margin-left: 5px;">
                            </div>
                            <div class="userMajor"><span v-if="data.user.address.cityName">{{data.user.address.cityName[1]}}</span><span v-if="data.user.major && data.user.address.cityName"> | </span>{{data.user.major}}</div>
                            <div class="userMajor">{{data.creationTime}}</div>
                        </div>
                    </div>

                    <div class="action" v-if="user.uuid == data.user.uuid">
                        <el-dropdown @command="more_action">
					<span class="el-dropdown-link">
						<i class="el-icon-caret-bottom" style="font-size: 24px"></i>
					</span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item v-if="user.uuid == data.user.uuid" command="edit">编辑内容</el-dropdown-item>

<!--                                <el-dropdown-item v-if="user.uuid == data.user.uuid" command="weChart">微信分享</el-dropdown-item>-->

<!--                                <el-dropdown-item command="share">转移到其他Room</el-dropdown-item>-->

<!--                                <el-dropdown-item command="share">IM给好友</el-dropdown-item>-->

<!--                                <el-dropdown-item command="room">转发朋友圈</el-dropdown-item>-->

                                <el-dropdown-item v-if="user.uuid == data.user.uuid"  command="delete" >删除内容</el-dropdown-item>

                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
                <!-- 内容 -->
                <div class="substance">
                    <span class="contentType hand op" @click="toRoom">
                        <i class="el-icon-s-home" style="margin-right: 5px;"></i>
                        <span>{{data.roomName}}</span>
                    </span>
                    <div class="t" style="text-align: center; margin: 2px 0 10px 0; font-size: 16px; font-weight: 600" v-html="data.content.title"></div>
                    <div class="t content" v-html="data.content.content"></div>
                </div>
                <div style="margin-top: 20px" v-if="data.content.enclosure.length > 0">
                  <div class="file-box hand op" @click="downloadFiles(item.url, item.name)" v-for="(item, index) in data.content.enclosure" :key="index">
                    <img src="./imgs/files.png" style="width: 24px; height: 20px; margin-right: 12px; vertical-align: middle">
                    <span style="font-size: 18px; vertical-align: middle">{{item.name}}</span>
                  </div>
                </div>
              <div style="margin: 20px">
                <DynamicOperation :data="data"></DynamicOperation>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DynamicOperation from "@/pages/room/components/DynamicOperation.vue";

    export default {
        name: "articleInfo",
        components: {DynamicOperation},
        computed: {
            user() {
                return this.$store.state.user
            },
            is_login(){
                return this.$store.state.is_login
            },
        },
        data () {
            return {
                data: {
                    user: {},
                    content: {
                      enclosure: []
                    }
                },
                content_list: [],
            }
        },

        methods: {
            // 跳转到Room
            toRoom() {
                this.$router.push({ path: '/room/info/' + this.data.roomId})
            },

            // 下载附件
            downloadFiles(url, name) {
              this.utils.download(url, name)
            },

            //下拉菜单触发的方法
            more_action:function(e){
                if (this.is_login == 0) {
                    this.err("请先登录");
                    return;
                }
                var that = this;
                if (e == "delete") {
                    that.$confirm('删除后无法恢复，确认删除吗？')
                        .then(_ => {
                            console.log(_);
                          this.newApi.delRoomContent({id:that.data.id}).then(ret =>{
                            if(ret.isSuccess == 1){
                              that.utils.sus(ret.data);
                              that.show = false;
                            }
                          })
                        })
                        .catch(_ => {
                            console.log(_);
                        });
                }

                if (e == "edit") {
                  this.$router.push({name: 'Acticle', query: {id: this.data.id}})
                }
            },


            // 获取内容
            getRoomContent() {
                var that = this;
                this.newApi.getRoomContentInfo({id: this.$route.params.id}).then(res =>{
                    that.data = res.data
                })
            },
        },

        beforeMount() {

        },

        mounted() {
            this.getRoomContent();
        }
    }
</script>

<style scoped>
    .image-info {
        margin: 0 auto;
        width: 1500px;
        min-height: 100vh;
        display: flex;
    }

    .image-left {
        width: 74%;
        margin-right: 10px;
    }

    .image-right {
        width: 25%;
        padding: 10px 16px;
        margin-bottom: 10px;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }

    .Dynamic{
        padding: 24px 38px 10px;
        margin-bottom: 10px;
        background-color: #ffffff;
        box-shadow: 0 3px 6px 1px rgba(0,0,0,0.1600);
    }
    .Dynamic .file-box {
      height: 32px;
      padding: 8px 10px;
      margin-bottom: 4px;
      background-color: #F1F1F1;
    }
    .Dynamic .head{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .head .user{
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
    .Dynamic .head .user .userIco{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        margin-right: 12px;
    }
    .Dynamic .head .user .major .userName{
        font-weight: 600;
        color: #444444;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items:flex-end;
    }
    .Dynamic .head .user .major .userMajor{
        font-size: 13px;
        color: #999999;
        margin-top: 5px;

    }
    .Dynamic .substance .t{
        width: 100%;
        height: 100%;
        color: #555555;
        margin-right: 10px;
        font-size:14px;
    }
    .Dynamic .substance{
        margin-top: 20px;
        line-height: 26px;
        font-size: 15px;
    }
    .Dynamic .substance .contentType{
        color: #031F88;
        height: 16px;
        font-weight: 600;
        margin-right: 10px;
    }
    .Dynamic .imgs{
        margin-top: 14px;
    }
    .spacebetween{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .Dynamic .volume{
        height: 35px;
        line-height: 35px;
        color: #999999;
        font-size: 14px;
    }
    .Dynamic .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:50px;
        height: 50px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }
    .Dynamic .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }

    /*.Dynamic>>>img {*/
    /*    width: 100%;*/
    /*}*/
    .mr5{
        margin-right: 5px;
    }
    .t >>> img {
        max-width: 100%;
    }

    .Dynamic .content {
      overflow-x: auto;
    }

</style>
