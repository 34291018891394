<template>
    <div class="dynamic-operation">
        <div class="bottom">
            <div class="btn hand op" @click="showCommentInfo">
                <img src="../imgs/reply.png" style="width: 16px;height: auto;margin-right: 10px;">评论（{{JSON.stringify(data.replyNum) ? data.replyNum : data.replyCount}}）
            </div>
<!--            <div v-if="data.isThumbsUp == 0" class="btn" @click="clickLike">-->
<!--                <img src="../imgs/fabulous.png" style="width: 16px;height: auto;margin-right: 10px;">点赞（{{data.thumbsUpCount}}）-->
<!--            </div>-->
<!--            <div v-else class="btn" style="color: #FFA500;" @click="clickLike">-->
<!--                <img src="../imgs/fabulous.png" style="width: 16px;height: auto;margin-right: 10px;">已点赞（{{data.thumbsUpCount}}）-->
<!--            </div>-->
        </div>

        <div class="show-comment" v-if="showComment == true">
            <el-divider></el-divider>
            <div style="display: flex; margin-bottom: 10px">
                <img :src="user.headIco" style="width: 30px; height: 30px; border-radius: 50%; margin-right: 10px"/>
                <el-input style="height: 30px; margin-right: 12px" placeholder="发布你的评论" v-model="sendContent"></el-input>
            </div>
            <div style="display: flex; justify-content: end; margin: 20px 10px">
                <span class="hand op comment-btn" v-if="sendContent !== ''" @click="addCommand">评论</span>
                <span class="comment-btn" v-else style="background-color: #D8DADF; pointer-events: none">评论</span>
            </div>
            <el-divider></el-divider>
            <div v-for="(item, index) in commentData" :key="index">
                <div style="display: flex; justify-content: space-between; margin-bottom: 20px">
                    <div style="display: flex" class="hand op" @click="openInfo(item.user.uuid, item.user.userType)">
                        <img :src="item.user.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px; margin-top: 4px">
                        <div style=" margin-top: 2px; margin-right: 15px;">
                            <div style="font-weight: 600; color: #2970FF">{{item.user.nickName}}</div>
                            <div>{{item.content}}</div>
                            <div style="color: #999999; font-size: 10px; margin-top: 4px">{{item.creationTime}}</div>
                        </div>
                    </div>
                    <span class="hand op" v-if="user.uuid === data.user.uuid || user.uuid === item.user.uuid" style="color: #1C7EFF; margin-left: 10px" @click="deleteContent(item.id)">删除</span>
                </div>
                <div style="display: flex; justify-content: space-between; margin-bottom: 12px; margin-left: 30px" v-for="(reply, num) in item.sub" :key="num">
                    <div style="display: flex;">
                        <img :src="reply.user.headIco" style="width: 36px; height: 36px; border-radius: 50%; margin-right: 12px; margin-top: 4px">
                        <div>
                            <div style=" margin-top: 2px; margin-right: 15px;">
                                <div style="font-weight: 600; color: #2970FF">{{reply.user.nickName}}</div>
                                <div>{{reply.content}}</div>
                                <div style="color: #999999; font-size: 8px; margin-top: 8px">{{reply.creationTime}}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <span class="hand op" style="color: #1C7EFF;" @click="showEditor(item)">回复</span>
                    </div>
                </div>

            </div>
            <div v-if="commentData.length % 10 === 0 && commentData.length !== 0" class="hand" style="color: #1C7EFF; margin-left: 42%" @click="getMoreComment">查看更多评论 <i class="el-icon-arrow-down"></i></div>
        </div>

        <el-dialog :visible.sync="showLabel">
            <div style="padding: 20px; display: flex">
                <span style="font-size: 18px; font-weight: 600">标签名： </span>
                <el-input style="width: 400px" v-model="labelName"></el-input>
            </div>
            <div style="display: flex; justify-content: end">
                <el-button class="cel-btn" @click="cancelLabel">取消</el-button>
                <el-button class="save-btn" @click="saveLabel">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "DynamicOperation",
        props: {
            data: {
                type: Object,
                default() {
                    return {}
                }
            }
        },

        data() {
            return {
                labelName: '',
                showLabel: false,

                showComment: false,
                showReply: false,
                sendContent: '',
                replyContent: '',
                replyInfo: {
                    title: '',
                    cId: ''
                },

                page: 1,
                more: 1,
                pageSize: 10,

                commentData: [],
                replyData: []
            }
        },

        computed: {
            user() {
                return this.$store.state.user;
            },
            is_login(){
                return this.$store.state.is_login
            },
            router(){
                return this.$store.state.router
            }
        },

        mounted() {
        },

        methods: {
          // 点击打开用户信息
          openInfo(uuid, userType) {
            if (!this.utils.toAuth(1)) {
              return false;
            }
            if (userType === 1) {
              this.$router.push({ path: '/circle/mySpace/' + uuid})
            }
            if (userType === 2 || userType === 3) {
              this.$router.push({ path: '/circle/organSpace/' + uuid})
            }
          },

            //点击贴标签/收藏
            clickshowLabel(){
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false;
              }
                    that.showLabel = true;

            },

            // 获取回复
            getReply(index, id) {
                this.commentData[index].page ++;
                this.newApi.getCommentSub({id: id, page: this.commentData[index].page, pageSize: 10}).then(res => {
                    for (const item of res.data) {
                        this.commentData[index].sub.push(item);
                    }
                })
            },

            // 发表回复
            addReply() {
                this.newApi.addComment({type: 1, cId: this.replyInfo.cId, content: this.replyContent}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.replyContent = '';
                        this.showReply = false;
                        this.getComment();
                        this.utils.sus(res.data)
                    }
                })
            },

            // 添加评论
            addCommand() {
                this.newApi.addRoomContentReply({id: this.data.id, content: this.sendContent}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.sendContent = '';
                        this.data.replyNum ++;
                        this.getComment();
                        this.utils.sus(res.data)
                    }
                })
            },

            // 删除评论
            deleteContent(id) {
              this.newApi.delRoomContentReply({id: id}).then(res =>{
                if (res.isSuccess == 1) {
                  this.getComment();
                  this.data.replyNum --;
                  this.utils.sus(res.data)
                }
              })
            },

            // 展示回复
            showEditor(item) {
                this.showReply = true;
                this.replyInfo.title = '回复' + item.user.nickName;
                this.replyInfo.cId = item.id
            },

            // 展示评论内容
            showCommentInfo() {
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false;
              }
                    that.showComment = !that.showComment;
                    that.getComment()

            },

            getComment() {
                this.page = 1;
                if(this.showComment === true) {
                    this.newApi.getRoomContentReply({id: this.data.id, page: this.page, pageSize: this.pageSize}).then(res => {
                        for (const item of res.data) {
                            item['page'] = 1
                        }
                        this.commentData = res.data;
                    })
                }
            },

            // 查看更多评论
            getMoreComment() {
                this.page ++;
                this.newApi.getComment({id: this.data.id, bgbType: this.data.bgbType, page: this.page, pageSize: this.pageSize}).then(res => {
                    for (const item of res.data) {
                        item['page'] = 1;
                        this.commentData.push(item);
                    }
                })
            },

            // 未开启评论提醒
            showCommentMessage() {
                this.utils.msg('作者未开启评论！')
            },

            // 转发
            toTranspond() {
                var that = this;
                if(that.user.realNameStatus == 0 || that.user.realNameStatus == 1){
                    that.utils.realNameAuthJump(that.user.uuid,that.user.realNameStatus,that.user.userType);
                    return;
                }else{
                    that.newApi.forward({id: that.data.id, bgbType: that.data.bgbType}).then(res =>{
                        if (res.isSuccess == 1) {
                            that.data.isForward = 1;
                            that.$emit('getForwardContent');
                            that.utils.sus(res.data)
                        }
                    })
                }
            },


            // 点赞、取消点赞
            clickLike() {
                var that = this;
              if (!this.utils.toAuth(1)) {
                return false;
              }
                    that.newApi.thumbsUp({id: that.data.id, type: that.data.bgbType}).then(res =>{
                        if (res.isSuccess == 1) {
                            if (that.data.isThumbsUp === 0) {
                                that.data.thumbsUpCount += 1;
                                that.data.isThumbsUp = 1
                            } else {
                                that.data.thumbsUpCount -= 1;
                                that.data.isThumbsUp = 0
                            }
                        }
                    })

            },

            // 保存标签
            saveLabel() {
                this.newApi.addCollection({bgbType: this.data.bgbType, label: this.labelName, id: this.data.id}).then(res =>{
                    if (res.isSuccess == 1) {
                        this.labelName = '';
                        this.showLabel = false;
                        this.data.isCollection = 1;
                        this.utils.sus(res.data)
                    }
                })
            },

            // 取消标签
            cancelLabel() {
                this.labelName = '';
                this.showLabel = false;
            },
        },
    }
</script>

<style scoped>
    .bottom{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height:26px;
        height: 26px;
        color: #444444;
        font-size: 16px;
        text-align: center;
    }

    .bottom .btn{
        display: flex;
        justify-content:center;
        align-items: center;
        flex: 1;
    }

    .dynamic-operation .save-btn {
        width: 50px;
        height: 32px;
        color: #fff;
        margin-top: 20px;
        padding: 10px;
        background-color: #1C7EFF;
        border-radius: 5px;
    }

    .dynamic-operation .cel-btn {
        width: 50px;
        height: 32px;
        color: #fff;
        margin-top: 20px;
        padding: 10px;
        background-color: #6d737b;
        border-radius: 5px;
    }

    .comment-btn {
        width: 34px;
        height: 12px;
        line-height: 12px;
        padding: 8px 18px;
        border-radius: 20px;
        background-color: #FF9800;
        color: #FFFFFF;
    }

    /*公用及el*/
    .hand{
        cursor:pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .op:active{
        opacity:0.6;
        -webkit-transition:all .1s;
        transition:all .1s;opacity:.6;
    }

    .op{
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all .5s;
        transition: all .5s;
    }

</style>
